.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &section- {
      &extra-small {
        padding: $section-extra-small;
      }

      &small {
        padding: $section-small;
      }

      &medium {
        padding: $section-medium;
      }

      &large {
        padding: $section-large;
      }

      &extra-large {
        padding: $section-extra-large;
      }
    }
  }
}

// Responsive
@import "section-responsive";
