@charset "utf-8";

body {
  &.travlr-ui {
    &.t-modal-active {
      overflow: hidden;
    }
  }
}

.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &modal {
      pointer-events: none;
      position: fixed;
      display: table;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 9999;
      opacity: 0;
      visibility: hidden;

      @include transition(all 0.2s ease);

      &.active {
        opacity: 1;
        visibility: visible;
        background-color: rgba(black, 0.8);
        pointer-events: visible;

        & .t-modal-overlay {
          opacity: 1;
          visibility: visible;

          @include transform(translate3d(0, 0, 0));
        }

        & .t-modal-content {
          opacity: 1;
          visibility: visible;
        }

        & .t-modal {
          opacity: 1;
          visibility: visible;
          background-color: rgba(black, 0.8);
          pointer-events: visible;
        }
      }

      &-content {
        margin: 0 auto;
        padding: $modal-padding;
        background-color: white;
        min-width: 464px;
        opacity: 0;
        visibility: visible;
        border-radius: $radius;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: auto;
        max-height: 80%;

        @include transform(translate3d(-50%, -50%, 0));
        @include transition($primary-transition);

        @include mobile {
          max-width: 100%;
          max-height: 100%;
          min-width: 100%;
          border-radius: 0;
          padding: 0;

          @include box-shadow($primary-box-shadow);
        }
      }

      &-header {
        position: relative;
        padding-bottom: 16px;
        text-align: left;

        @include mobile {
          padding: 14px;
          text-align: center;
          font-size: 16px;
          z-index: 9;

          @include box-shadow($primary-box-shadow);

          & .t-heading-4 {
            font-size: 16px;
            vertical-align: middle;
          }
        }
      }

      &-close {
        @include mobile {
          position: absolute;
          right: 16px;
        }
      }

      &-body {
        text-align: left;
        color: $color-grey-darkest;
        margin-top: 16px;

        @include mobile {
          padding: 16px;
          margin-top: 0;
          height: calc(100vh - 52px);
          overflow: auto;
        }
      }

      &-footer {
        text-align: right;
        padding-top: 32px;
      }

      &-overlay {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: 100%;
        z-index: 0;

        @include transition($primary-transition);
        @include transform(translate3d(0, 100%, 0));

        @include mobile {
          padding: 0;
          vertical-align: top;
          background-color: $color-grey-lightest;
          display: block;
          width: 100%;
        }
      }
    }
  }
}
