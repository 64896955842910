.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &ajax {
      &-full-loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10001;
        background-color: rgba(white, 0.6);
        opacity: 0;
        visibility: hidden;

        @include transition($primary-transition);
        text-align: center;

        &.active {
          opacity: 1;
          visibility: visible;
        }

        & .t-icon {
          position: absolute;
          top: 45%;
          left: 50%;

          @include transform(translate3d(-50%, -50%, 0));
        }
      }

      &-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background-color: rgba(white, 0.8);
        opacity: 0;
        visibility: hidden;

        @include transition($primary-transition);
        text-align: center;

        &.active {
          opacity: 1;
          visibility: visible;
        }

        & .t-icon {
          position: absolute;
          top: 45%;
          left: 50%;

          @include transform(translate3d(-50%, -50%, 0));
        }
      }

      @keyframes loop {
        0% {
          width: 0%;
          transform: translateX(0%);
        }

        7% {
          width: 10%;
          transform: translateX(0%);
        }

        40% {
          width: 60%;
        }

        50% {
          transform: translateX(85%);
        }

        75% {
          width: 100%;
        }

        100% {
          transform: translateX(100%);
        }
      }

      &-search-loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: rgba(white, 0.8);
        opacity: 0;
        visibility: hidden;

        @include transition($primary-transition);
        text-align: center;

        &.active {
          opacity: 1;
          visibility: visible;
        }

        &-logo {
          max-width: 180px;
        }

        &-content {
          max-width: 450px;
          background-color: white;
          padding: 24px;

          @include box-shadow($primary-box-shadow);
          text-align: center;
          border-radius: $radius;
          top: 50%;
          left: 50%;

          @include transform(translate3d(-50%, -50%, 0));
          position: relative;
          overflow: hidden;

          @include mobile {
            max-width: calc(100% - 32px);
          }
        }

        &-line-wrapper {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba($color-primary, 0.7);
          width: 100%;
          height: 4px;
        }

        &-line {
          background-color: $color-primary;
          height: 4px;
          animation: 1.7s infinite loop cubic-bezier(0.42, 0, 0.58, 1);
        }
      }
    }

    &inner-html {
      > {

        // Reset Body Typography
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: $weight-bold;

          @include typography-text-normal;
          margin: 12px 0;
        }

        h1 {
          font-size: $size-text-1;
          line-height: $line-height-text-1;
        }

        h2 {
          font-size: $size-text-2;
          line-height: $line-height-text-2;
        }

        h3 {
          font-size: $size-text-3;
          line-height: $line-height-text-3;
        }

        h4 {
          font-size: $size-text-4;
          line-height: $line-height-text-4;
        }

        h5 {
          font-size: $size-text-5;
          line-height: $line-height-text-5;
        }

        h6 {
          font-size: $size-text-6;
          line-height: $line-height-text-6;
        }

        ul {
          list-style: disc;
          list-style-position: inside;
          padding-left: 32px;

          li {
            font-size: $size-text-body;
            line-height: 2.2;
            font-weight: normal;

            @include typography-text-normal;

            > span,
            p,
            div,
            a {
              font-size: $size-text-body;
              line-height: $line-height-text-body;
              font-weight: normal;

              @include typography-text-normal;
            }
          }
        }
      }
    }

    &error {
      min-height: calc(100vh - 64px);

      @include has-background-image;

      &-heading {
        font-size: 64px;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
      }

      & .t-heading-1 {
        @include tablet {
          font-size: 20px;
          line-height: 1.4;
        }
      }

      &-content {
        max-width: 520px;
        margin: 0 auto;
        padding: 0 16px;
        z-index: 2;
      }

      &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 0;
      }

      &-text {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
      }
    }

    // Has Background
    &has-background-image {
      @include has-background-image;
      overflow: hidden;
    }

    &border {
      border: $primary-border;

      &-left {
        border-left: $primary-border;
      }

      &-right {
        border-right: $primary-border;
      }
    }

    // Border Radius
    &border-radius {
      border-radius: $radius;

      &-100 {
        border-radius: 100%;
      }
    }

    $borderamount: (top, bottom);
    $border-arrow: (left, right);

    @each $space in $borderamount {
      @each $side in $border-arrow {
        &no-border-radius-#{$space}-#{$side} {
          border-#{$space}-#{$side}-radius: 0 !important;
        }
      }
    }

    // No Border and Border Radius
    &no {
      // Start Border
      &-border {
        border: 0 !important;
      }
      // End Border

      // Start Border Radius
      &-border-radius {
        border-radius: 0 !important;
      }
      // End Border Radius

      &-mobile {
        @include mobile {
          // Start Border
          &-border {
            border: 0 !important;
          }
          // End Border

          // Start Border Radius
          &-border-radius {
            border-radius: 0 !important;
          }
          // End Border Radius
        }
      }
    }

    // Box Shadow
    &primary-box-shadow {
      @include box-shadow($primary-box-shadow);

      @include mobile {
        &-mobile {
          @include box-shadow($primary-box-shadow);
        }
      }
    }

    // Container
    &main-content {
      margin-top: $header-height;
      min-height: calc(100vh - 128px); // $header-height * 2
      position: relative;
      display: block;
    }

    // Colour Pallete
    &color- {

      //Theme Colours
      &primary {
        color: $color-primary;

        &-light {
          color: $color-primary-light;
        }

        &-dark {
          color: $color-primary-dark;
        }

        &-hover {
          &:hover {
            color: $color-primary;
          }

          &-light {
            color: $color-primary-light;
          }

          &-dark {
            color: $color-primary-dark;
          }
        }
      }

      &secondary {
        color: $color-secondary;

        &-light {
          color: $color-secondary-light;
        }

        &-dark {
          color: $color-secondary-dark;
        }

        &-hover {
          color: $color-secondary;

          &-light {
            color: $color-secondary-light;
          }

          &-dark {
            color: $color-secondary-dark;
          }
        }
      }

      &tertiary {
        color: $color-tertiary;

        &-light {
          color: $color-tertiary-light;
        }

        &-dark {
          color: $color-tertiary-dark;
        }

        &-hover {
          color: $color-tertiary;

          &-light {
            color: $color-tertiary-light;
          }

          &-dark {
            color: $color-tertiary-dark;
          }
        }
      }

      // Grayscale
      &white {
        color: $color-white;
      }

      &grey- {
        &lightest { color: $color-grey-lightest; }
        &lighter { color: $color-grey-lighter; }
        &light { color: $color-grey-light; }
        &med { color: $color-grey-med; }
        &dark { color: $color-grey-dark; }
        &darker { color: $color-grey-darker; }
        &darkest { color: $color-grey-darkest; }

        &50 { color: $color-grey-50; }
        &100 { color: $color-grey-100; }
        &200 { color: $color-grey-200; }
        &300 { color: $color-grey-300; }
        &400 { color: $color-grey-400; }
        &500 { color: $color-grey-500; }
        &600 { color: $color-grey-600; }
        &700 { color: $color-grey-700; }
        &900 { color: $color-grey-900; }
      }

      &black {
        color: $color-black;
      }

      // Alerts
      &danger {
        color: $color-danger;
      }

      &warning {
        color: $color-warning;
      }

      &success {
        color: $color-success;
      }
    }

    // Cursor
    $cursors: auto crosshair default grab help none pointer text zoom-in zoom-out;

    @each $cursor in $cursors {
      &cursor- {
        &#{$cursor} {
          cursor: $cursor !important;
        }
      }
    }

    &invisible {
      visibility: hidden !important;
    }

    &background- {

      //Theme Colours
      &primary {
        background-color: $color-primary;

        &-light {
          background-color: $color-primary-light;
        }

        &-dark {
          background-color: $color-primary-dark;
        }

        &-hover {
          background-color: $color-primary;

          &-light {
            background-color: $color-primary-light;
          }

          &-dark {
            background-color: $color-primary-dark;
          }
        }
      }

      &secondary {
        background-color: $color-secondary;

        &-light {
          background-color: $color-secondary-light;
        }

        &-dark {
          background-color: $color-secondary-dark;
        }

        &-hover {
          background-color: $color-secondary;

          &-light {
            background-color: $color-secondary-light;
          }

          &-dark {
            background-color: $color-secondary-dark;
          }
        }
      }

      &tertiary {
        background-color: $color-tertiary;

        &-light {
          background-color: $color-tertiary-light;
        }

        &-dark {
          background-color: $color-tertiary-dark;
        }

        &-hover {
          background-color: $color-tertiary;

          &-light {
            background-color: $color-tertiary-light;
          }

          &-dark {
            background-color: $color-tertiary-dark;
          }
        }
      }

      // Grayscale
      &white {
        background-color: $color-white;
      }

      &grey- {
        &lightest { background-color: $color-grey-lightest; }
        &lighter { background-color: $color-grey-lighter; }
        &light { background-color: $color-grey-light; }
        &med { background-color: $color-grey-med; }
        &dark { background-color: $color-grey-dark; }
        &darker { background-color: $color-grey-darker; }
        &darkest { background-color: $color-grey-darkest; }

        &50 { background-color: $color-grey-50; }
        &100 { background-color: $color-grey-100; }
        &200 { background-color: $color-grey-200; }
        &300 { background-color: $color-grey-300; }
        &400 { background-color: $color-grey-400; }
        &500 { background-color: $color-grey-500; }
        &600 { background-color: $color-grey-600; }
        &700 { background-color: $color-grey-700; }
        &900 { background-color: $color-grey-900; }
      }

      &black {
        background-color: $color-black;
      }

      // Alerts
      &danger {
        background-color: $color-danger;
      }

      &warning {
        background-color: $color-warning;
      }

      &success {
        background-color: $color-success;
      }

      &mobile- {
        @include mobile {
          &primary {
            background-color: $color-primary;

            &-light {
              background-color: $color-primary-light;
            }

            &-dark {
              background-color: $color-primary-dark;
            }

            &-hover {
              background-color: $color-primary;

              &-light {
                background-color: $color-primary-light;
              }

              &-dark {
                background-color: $color-primary-dark;
              }
            }
          }

          &secondary {
            background-color: $color-secondary;

            &-light {
              background-color: $color-secondary-light;
            }

            &-dark {
              background-color: $color-secondary-dark;
            }

            &-hover {
              background-color: $color-secondary;

              &-light {
                background-color: $color-secondary-light;
              }

              &-dark {
                background-color: $color-secondary-dark;
              }
            }
          }

          &tertiary {
            background-color: $color-tertiary;

            &-light {
              background-color: $color-tertiary-light;
            }

            &-dark {
              background-color: $color-tertiary-dark;
            }

            &-hover {
              background-color: $color-tertiary;

              &-light {
                background-color: $color-tertiary-light;
              }

              &-dark {
                background-color: $color-tertiary-dark;
              }
            }
          }

          // Grayscale
          &white {
            background-color: $color-white;
          }

          &grey- {
            &lightest {
              background-color: $color-grey-lightest;
            }

            &lighter {
              background-color: $color-grey-lighter;
            }

            &light {
              background-color: $color-grey-light;
            }

            &med {
              background-color: $color-grey-med;
            }

            &dark {
              background-color: $color-grey-dark;
            }

            &darker {
              background-color: $color-grey-darker;
            }

            &darkest {
              background-color: $color-grey-darkest;
            }
          }

          &black {
            background-color: $color-black;
          }

          // Alerts
          &danger {
            background-color: $color-danger;
          }

          &warning {
            background-color: $color-warning;
          }

          &success {
            background-color: $color-success;
          }
        }
      }
    }

    // Floating
    &clearfix {
      @include clearfix;
    }

    $floats: left right unset inherit none;

    @each $float in $floats {
      &float- {

        &#{$float} {
          float: $float !important;
        }

        @include mobile {
          &#{$float}-mobile {
            float: #{$float} !important;
          }
        }

        @include tablet {
          &#{$float}-tablet {
            float: #{$float} !important;
          }
        }
      }
    }

    &label {
      &-code {
        background-color: $color-grey-lightest;
        border: 1px solid $color-grey-light;
        padding: 0 5px;
        border-radius: $radius;
        color: $color-danger;
        font-family: $typeface-code;

        &-small {
          background-color: $color-grey-lightest;
          border: 1px solid $color-grey-light;
          padding: 0 5px;
          border-radius: $radius;
          color: $color-danger;
          font-family: $typeface-code;
          font-size: 10px;
        }
      }
    }

    // Separator
    &separator {
      width: 100%;
      max-width: $container-width;
      margin: 20px auto;
      border-bottom: 1px solid $color-grey-lighter;

      &-small {
        margin: 16px 0;
      }

      &-middle {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);

        @include transform(translate3d(-50%, -50%, 0));
        margin: 0 auto;
        left: 50%;
        width: calc(100% - 24px);
        z-index: 0;
        background-color: #CCC;
      }

      &-dashed {
        border-bottom: 1px dashed $color-grey-lighter;
      }

      &-dotted {
        border-bottom: 1px dotted $color-grey-lighter;
      }
    }

    // Text
    &middle-text {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    $alignments: ('center': 'center', 'justify': 'justify', 'left': 'left', 'right': 'right');

    @each $alignment, $text-align in $alignments {
      &text-align-#{$alignment} {
        text-align: #{$text-align} !important;
      }

      @include mobile {
        &text-align-#{$alignment}-mobile {
          text-align: #{$text-align} !important;
        }
      }
    }

    $fontsizes: (0, 4, 5, 6, 8, 10, 12, 15, 16, 20, 24, 26, 28, 30, 32, 36, 40, 46, 48, 64);

    @each $fontsize in $fontsizes {
      &fs-#{$fontsize} {
        font-size: #{$fontsize}px !important;
      }

      @include mobile {
        &fs-#{$fontsize}-mobile {
          font-size: #{$fontsize}px !important;
        }
      }

      @include tablet {
        &fs-#{$fontsize}-tablet {
          font-size: #{$fontsize}px !important;
        }
      }
    }

    $text-decorations: overline line-through underline;

    @each $decoration in $text-decorations {
      &text- {
        &#{$decoration} {
          text-decoration: $decoration !important;
        }
      }
    }

    $font-weights: normal bold 100 200 400 500 600 700;

    @each $weight in $font-weights {
      &font-weight- {
        &#{$weight} {
          font-weight: $weight !important;
        }
      }
    }

    &hyphens {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }

    &ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &ellipsis-1-line {
      @include ellipsis(1);
    }

    &ellipsis-2-line {
      @include ellipsis(2);
    }

    &ellipsis-3-line {
      @include ellipsis(3);
    }

    $sizes: (
      1: $size-text-1,
      2: $size-text-2,
      3: $size-text-3,
      4: $size-text-4,
      5: $size-text-5,
      6: $size-text-6,
      body: $size-text-body,
      caption: $size-text-caption,
      small: $size-text-small
    );

    @each $heading, $size in $sizes {
      &text-size-#{$heading} {
        font-size: $size;
      }
    }

    $styles: normal italic unset;

    @each $style in $styles {
      &font-style- {

        &#{$style} {
          font-style: $style !important;
        }
      }
    }

    $transforms: capitalize lowercase uppercase;

    @each $transform in $transforms {
      &text- {

        &#{$transform} {
          text-transform: $transform !important;
        }
      }
    }

    // Typeface
    &typeface-primary {
      font-family: $typeface-primary;
    }

    // Overflow
    $overflows: inherit initial auto scroll hidden visible unset;

    @each $overflow in $overflows {
      &overflow- {

        &#{$overflow} {
          overflow: $overflow !important;
        }
      }
    }

    // Overlay

    // Position
    $positions: relative absolute fixed sub super unset;

    @each $position in $positions {
      &position- {

        &#{$position} {
          position: $position !important;
        }
      }
    }

    // Placeholders
    &unselectable {
      @include unselectable;
    }

    // Padding & Margin
    &no- {
      &margin {
        margin: 0 !important;
      }

      &padding {
        padding: 0 !important;
      }

      @include mobile {
        &mobile {
          &-margin {
            margin: 0 !important;
          }

          &-padding {
            padding: 0 !important;
          }
        }
      }
    }

    $spaceamounts: (0, 4, 5, 6, 8, 10, 12, 15, 16, 20, 24, 26, 28, 30, 32, 36, 40, 46, 48, 64);
    $sides: (top, bottom, left, right);

    @each $space in $spaceamounts {
      @each $side in $sides {

        &m-#{str-slice($side, 0, 1)}-#{$space} {
          margin-#{$side}: #{$space}px;
        }

        &p-#{str-slice($side, 0, 1)}-#{$space} {
          padding-#{$side}: #{$space}px;
        }

        @include mobile {
          &mobile- {
            &m-#{str-slice($side, 0, 1)}-#{$space} {
              margin-#{$side}: #{$space}px !important;
            }

            &p-#{str-slice($side, 0, 1)}-#{$space} {
              padding-#{$side}: #{$space}px !important;
            }
          }
        }

        @include tablet {
          &tablet- {
            &m-#{str-slice($side, 0, 1)}-#{$space} {
              margin-#{$side}: #{$space}px !important;
            }

            &p-#{str-slice($side, 0, 1)}-#{$space} {
              padding-#{$side}: #{$space}px !important;
            }
          }
        }
      }
    }

    // Visibility
    &hidden {
      display: none !important;

      @include mobile {
        &-mobile {
          display: none !important;
        }
      }

      @include tablet {
        &-tablet {
          display: none !important;
        }
      }
    }

    &show {
      display: block !important;

      @include mobile {
        &-mobile {
          display: block !important;
        }
      }

      @include tablet {
        &-tablet {
          display: block !important;
        }
      }
    }

    @include mobile {
      &invisible-mobile {
        visibility: hidden !important;
      }
    }

    @include tablet {
      &invisible-tablet {
        visibility: hidden !important;
      }
    }

    $displays: 'block' 'flex' 'inline' 'inline-block' 'inline-flex' 'table' 'table-cell';

    @each $display in $displays {
      &display-#{$display} {
        display: #{$display};
      }

      @include mobile {
        &display-#{$display}-mobile {
          display: #{$display} !important;
        }
      }

      @include tablet {
        &display-#{$display}-tablet {
          display: #{$display} !important;
        }
      }
    }

    // Vertical Align
    $v-aligns: top bottom middle sub super unset;

    @each $v-align in $v-aligns {
      &v-align- {

        &#{$v-align} {
          vertical-align: $v-align !important;
        }
      }
    }

    // Width
    &w- {
      &icon {
        width: 24px;
      }

      &auto {
        width: auto;
      }

      &100 {
        width: 100%;
      }

      &90 {
        width: 90%;
      }

      &50 {
        width: 50%;
      }

      @include mobile {
        &mobile- {
          &auto {
            width: auto;
          }

          &100 {
            width: 100%;
          }

          &90 {
            width: 90%;
          }

          &50 {
            width: 50%;
          }
        }
      }

      @include tablet {
        &tablet- {
          &auto {
            width: auto;
          }

          &100 {
            width: 100%;
          }

          &90 {
            width: 90%;
          }

          &50 {
            width: 50%;
          }
        }
      }
    }

    // Height
    &h- {
      &auto {
        height: auto;
      }

      &100 {
        height: 100%;
      }

      &90 {
        height: 90%;
      }

      &50 {
        height: 50%;
      }

      @include mobile {
        &mobile- {
          &auto {
            height: auto;
          }
          
          &100 {
            height: 100%;
          }

          &90 {
            height: 90%;
          }

          &50 {
            height: 50%;
          }
        }
      }

      @include tablet {
        &tablet- {
          &auto {
            height: auto;
          }
          
          &100 {
            height: 100%;
          }

          &90 {
            height: 90%;
          }

          &50 {
            height: 50%;
          }
        }
      }
    }

    // Misc
    &line-override {
      position: relative;
      z-index: 9;
    }

    &price-range {
      &-fields {
        position: relative;

        input {
          padding-left: 32px !important;
          padding-right: 16px !important;
        }
      }

      &-label {
        position: absolute;
        font-size: 14px;
        left: 12px;
        top: 50%;

        @include transform(translateY(-50%));
      }
    }

    $indexs: (0, 4, 5, 6, 8, 10, 12, 15, 16, 20, 24, 26, 32, 36, 40, 46, 48, 64);

    @each $index in $indexs {
      &index- {

        &#{$index} {
          z-index: $index !important;
        }
      }
    }

    &no-white-space {
      white-space: normal;

      &-mobile {
        @include mobile {
          white-space: normal;
        }
      }
    }

    &icon-favorite {
      @include transition($primary-transition);

      &.favorited,
      &:hover {
        &::before {
          content: "\EA90" !important;

          @include icon-default;
          color: $color-danger;
        }
      }
    }

    &ul-with-list {
      list-style: disc;
      list-style-position: outside;
      padding-left: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
    }

    &floating {
      &-top-filter {
        position: fixed;
        top: 64px;
        left: 0;
        z-index: 10;
        width: 100%;
        background-color: $color-tertiary;
        color: white;
        padding: 4px 14px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        @include transition($primary-transition);

        button {
          color: white;
          font-size: 14px;
          background: transparent !important;
          border-color: transparent !important;
          font-weight: normal;

          &:hover,
          &:active,
          &:focus {
            background: transparent !important;
            border-color: transparent !important;
          }
        }
      }

      &-filter {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        background-color: white;
        padding: 8px;

        @include box-shadow( 0 -1px 0 0 rgba(0, 0, 0, 0.2));
        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        @include transition($primary-transition);
      }

      &-active {
        opacity: 1;
        visibility: visible;
        pointer-events: fill;
      }

      &-submit {
        bottom: 0;
        left: 0;
        z-index: 1001;
        width: 100%;
        position: fixed;

        @include box-shadow(0 -1px 0 0 rgba(0, 0, 0, 0.2));
      }

      &-input-event {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }

    &icon-favorited {
      &::before {
        font-family: $typeface-travlr-icon;
        content: "\EA90" !important;
        color: $color-danger;
      }
    }

    // new grid system // latest update by gilang putra [HOTFIX] Add new grid system and helper class (#44)
    &#{$travlr-class-migration} {
      & [class*="t-visible-"] {
        display: none !important;
      }

      & [class*="t-hidden-"] {
        display: block !important;
      }
      & .#{$travlr-prefix} {
        &visible-xs {
          display: block !important;
        }

        &hidden-xs {
          display: none !important;
        }

        @include media-up(sm) {
          &visible-sm {
            display: block !important;
          }

          &hidden-sm {
            display: none !important;
          }
        }

        @include media-up(md) {
          &visible-md {
            display: block !important;
          }

          &hidden-md {
            display: none !important;
          }
        }

        @include media-up(lg) {
          &visible-lg {
            display: block !important;
          }

          &hidden-lg {
            display: none !important;
          }
        }

        @include media-up(xl) {
          &visible-xl {
            display: block !important;
          }

          &hidden-xl {
            display: none !important;
          }
        }
      }
    }

    // Align Items
    $alignItems: 'flex-start' 'flex-end' 'center' 'baseline' 'stretch';

    @each $alignItem in $alignItems {
      &items-#{$alignItem} {
        align-items: #{$alignItem};
      }

      @include mobile {
        &items-#{$alignItem}-mobile {
          align-items: #{$alignItem} !important;
        }
      }

      @include tablet {
        &items-#{$alignItem}-tablet {
          align-items: #{$alignItem} !important;
        }
      }
    }

    // Justify Content
    $justifyContent: 'flex-start' 'flex-end' 'center' 'space-between' 'space-around' 'space-evenly';

    @each $justifyContentItem in $justifyContent {
      &justify-#{$justifyContentItem} {
        justify-content: #{$justifyContentItem};
      }

      @include mobile {
        &justify-#{$justifyContentItem}-mobile {
          justify-content: #{$justifyContentItem} !important;
        }
      }

      @include tablet {
        &justify-#{$justifyContentItem}-tablet {
          justify-content: #{$justifyContentItem} !important;
        }
      }
    }

    // Flex Direction
    $flexDirection: 'column' 'column-reverse' 'revert' 'row' 'row-reverse' 'unset';

    @each $flexDirectionItem in $flexDirection {
      &flex-direction-#{$flexDirectionItem} {
        flex-direction: #{$flexDirectionItem};
      }

      @include mobile {
        &flex-direction-#{$flexDirectionItem}-mobile {
          flex-direction: #{$flexDirectionItem} !important;
        }
      }

      @include tablet {
        &flex-direction-#{$flexDirectionItem}-tablet {
          flex-direction: #{$flexDirectionItem} !important;
        }
      }
    }

    // Flex Wrap
    $flexWrap: 'nowrap' 'revert' 'unset' 'wrap' 'wrap-reverse';

    @each $flexWrapItem in $flexWrap {
      &flex-wrap-#{$flexWrapItem} {
        flex-wrap: #{$flexWrapItem};
      }

      @include mobile {
        &flex-wrap-#{$flexWrapItem}-mobile {
          flex-wrap: #{$flexWrapItem} !important;
        }
      }

      @include tablet {
        &flex-wrap-#{$flexWrapItem}-tablet {
          flex-wrap: #{$flexWrapItem} !important;
        }
      }
    }
  }
}
