@charset "utf-8";

.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &breadcrumb {
      &-item {
        display: inline-block;

        @include transition($primary-transition);
        cursor: pointer;

        > a,
        span,
        div,
        p {
          color: $color-grey-dark;

          @include transition($primary-transition);
          cursor: pointer;
          font-weight: $weight-semibold;
          font-size: $size-text-body;
          line-height: $line-height-text-callout;

          @include typography-text-normal;
        }

        &:first-child {
          padding-left: 0 !important;
        }

        &:last-child {
          padding-right: 0 !important;

          &::after {
            content: '';
            padding: 0 !important;
          }
        }

        &::after {
          padding: 0 12px;
          content: '/';
          font-weight: $weight-semibold;
          font-size: $size-text-body;
          line-height: $line-height-text-callout;

          @include typography-text-normal;
          color: $color-grey-dark;
        }

        &.active {
          > a,
          span,
          div,
          p {
            color: $color-tertiary !important;
          }
        }

        &:hover {
          > a,
          span,
          div,
          p {
            color: $color-secondary !important;
          }
        }

        &.disabled {
          cursor: not-allowed;

          > a,
          span,
          div,
          p {
            color: $color-grey-dark !important;
            cursor: not-allowed;
            text-decoration: none;
            pointer-events: none;
          }
        }
      }
    }
  }
}
