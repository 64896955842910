.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &time-picker {
      &-large {
        /deep/ {
          & .#{$element-prefix} {
            &input {
              &__inner {
                line-height: 48px !important;
                height: 48px !important;
              }
            }
          }
        }
      }

      &-transparent {
        /deep/ {
          & .#{$element-prefix} {
            &input {
              &__inner {
                background-color: transparent !important;
              }
            }
          }
        }
      }

      // Override Element Plugin
      /deep/ {
        & .#{$element-prefix} {
          &input {
            &__inner {
              border: 0;
              border-radius: 0;
              line-height: 38px;
              padding-right: 0;
              padding-left: 0;
              height: 38px;

              @include placeholder {
                color: $color-grey-dark;
                font-size: 14px;
              }
            }

            &__suffix {
              & .el-input__icon {
                opacity: 0;
                visibility: hidden;

                &.el-icon-circle-close {
                  @include transform(translateY(0) !important);
                  top: 12px !important;
                  opacity: 1;
                  visibility: visible;

                  @include transition($primary-transition);
                }
              }
            }

            [class^="el-icon-"],
            [class*=" el-icon-"] {
              @include icon-default;
            }

            .el-icon-time {
              color: $color-grey-dark !important;
              position: absolute;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              font-size: 20px !important;
              height: auto;

              &::before {
                content: "\EAD3";
              }
            }

            .el-icon-circle-close {
              color: $color-grey-dark !important;
              position: absolute;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              font-size: 20px !important;
              height: auto;
              right: 0;

              &::before {
                @include icon-default;
                content: "\ea4a";
              }
            }
          }
        }
      }
    }
  }
}
