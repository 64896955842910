.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &btn {

      // Variants
      &-primary,
      &-secondary,
      &-tertiary,
      &-default,
      &-inverted,
      &-anchor,
      &-grey {
        display: inline-block;
        padding: $button-padding;
        border-radius: $radius;
        font-size: $button-font-size;
        color: white;
        text-align: center;
        font-weight: 600;
        outline: 0;
        border: solid 1px transparent;
        cursor: pointer;
        line-height: 24px;

        @include typography-text-normal;
        @include no-box-shadow;
        @include transition($primary-transition);

        &:disabled,
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .t-icon {
          padding-right: 8px;
          font-size: 20px;
        }
      }

      &-transparent {
        display: inline-block;
        font-size: $button-font-size;
        color: $color-grey-med;

        @include no-box-shadow;
        padding: 0;
        outline: 0;
        cursor: pointer;

        @include transition($primary-transition);
        background-color: transparent;
        border: none;

        .t-icon {
          padding-right: 8px;
          font-size: 20px;
        }
      }

      &-read-more {
        display: inline-block;
        color: $color-primary;
        padding: 0;
        outline: 0;
        cursor: pointer;
        background-color: transparent;
        border: none;
        font-weight: $weight-bold;
        font-size: $size-text-5;
        line-height: $line-height-text-5;

        @include no-box-shadow;
        @include transition($primary-transition);
        @include typography-text-normal;

        &.active {
          &::after {
            content: '\ea0b';

            @include icon-default;
            @include transform(translateY(8px));
          }
        }

        &::after {
          content: '\EA05';
          position: relative;

          @include icon-default;
          @include transform(translateY(8px));
        }
      }

      &-group {
        display: table;
        width: auto;

        &-item {
          border: solid 1px $color-grey-lighter;
          border-radius: $radius;
          display: table-cell;
          padding: $button-padding;
          font-size: $size-text-caption;
          color: $color-grey-dark;
          text-align: center;
          font-weight: 600;
          outline: 0;
          cursor: pointer;

          @include typography-text-normal;
          @include transition($primary-transition);

          &-primary {
            &:active,
            &.active,
            &:hover,
            &:focus,
            &:visited {
              background-color: $color-primary-light;
              color: white;
              border-color: $color-primary-light;
            }
          }

          &-secondary {
            &:active,
            &.active,
            &:hover,
            &:focus,
            &:visited {
              background-color: $color-secondary-light;
              color: white;
              border-color: $color-secondary-light;
            }
          }

          &:first-child {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-right: 0;
          }

          &:last-child {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            border-left: 0;
          }

          &:disabled,
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }

      // Theme Colours
      &-primary {
        background-color: $color-primary;

        &:active,
        &.active {
          background-color: $color-primary-dark;
        }

        &:hover,
        &:focus,
        &:visited {
          background-color: $color-primary-light;
        }

        &:disabled,
        &.disabled {
          background-color: $color-disabled !important;
          color: $color-grey-darkest !important;
        }
      }

      &-secondary {
        background-color: $color-secondary;

        &:active,
        &.active {
          background-color: $color-secondary-dark;
        }

        &:hover,
        &:focus,
        &:visited {
          background-color: $color-secondary-light;
        }

        &:disabled,
        &.disabled {
          background-color: $color-disabled !important;
          color: $color-grey-darkest !important;
        }
      }

      &-tertiary {
        background-color: $color-tertiary;

        &:active,
        &.active {
          background-color: $color-tertiary-dark;
        }

        &:hover,
        &:focus,
        &:visited {
          background-color: $color-tertiary-light;
        }

        &:disabled,
        &.disabled {
          background-color: $color-disabled !important;
          color: $color-grey-darkest !important;
        }
      }

      &-default {
        background-color: transparent;
        color: $color-secondary;
        border: solid 1px $color-secondary;

        &:active,
        &.active {
          border-color: $color-secondary-dark;
          color: $color-secondary-dark;
        }

        &:hover,
        &:focus,
        &:visited {
          border-color: $color-secondary-light;
          color: $color-secondary-light;
        }

        &:disabled,
        &.disabled {
          border-color: $color-grey-med;
          color: $color-grey-dark;
        }
      }

      &-inverted {
        background-color: transparent;
        border: solid 1px white;

        &:active,
        &.active {
          border-color: $color-grey-med;
          color: $color-grey-med;
        }

        &:hover,
        &:focus,
        &:visited {
          border-color: $color-grey-lighter;
          color: $color-grey-lighter;
        }

        &:disabled,
        &.disabled {
          border-color: white;
        }
      }

      &-anchor {
        background-color: transparent !important;
        color: $color-secondary;
        border: solid 1px transparent !important;

        &:active,
        &.active {
          background-color: rgba($color-secondary-light, 0.2) !important;
        }

        &:hover,
        &:focus,
        &:visited {
          background-color: rgba($color-secondary-light, 0.1) !important;
        }

        &:disabled,
        &.disabled {
          color: $color-grey-dark;
        }
      }

      &-grey {
        background-color: transparent;
        color: $color-grey-900;
        border: solid 1px $color-grey-300;

        .t-icon {
          color: $color-grey-500;
        }

        &:hover,
        &:focus,
        &:visited,
        &:active,
        &.active {
          background-color: $color-grey-300;

          .t-icon {
            color: $color-grey-900;
          }
        }

        &:disabled,
        &.disabled {
          border-color: $color-grey-med;
          color: $color-grey-dark;

          .t-icon {
            color: $color-grey-dark;
          }
        }
      }

      // Type
      &-full {
        display: block;
        width: 100%;
      }

      @include mobile {
        &-full-on-mobile {
          display: block !important;
          width: 100% !important;
        }
      }

      @include tablet {
        &-full-on-tablet {
          display: block !important;
          width: 100% !important;
        }
      }

      &-not-allowed {
        cursor: not-allowed;
      }

      &-with-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      // Size
      &-small {
        font-size: $button-font-size-small !important;
        padding: $button-padding-small !important;
      }

      &-large {
        font-size: $button-font-size-large !important;
        padding: $button-padding-large !important;
      }

      &.is-loading {
        position: relative;
        background-color: $color-grey-dark;

        @include transition($primary-transition);

        &::after {
          @include transition($primary-transition);
          content: '\ead2';

          @include icon-default;

          @extend .t-icon-is-spinning;
          font-size: 20px !important;
          position: relative;
          z-index: 9;
          margin-left: 5px;
          top: 5px;
        }
      }
    }
  }
}
