// Notification
@mixin top-right($is-sticky) {
  @if $is-sticky {
    right: $notification-position-right !important;
    top: $notification-position-top !important;
  }

  @else {
    right: 0;
    top: 0;
  }

  @include mobile {
    right: 50% !important;
    bottom: auto !important;

    @if $is-sticky {
      top: $notification-position-top !important;
    }

    @else {
      top: 0 !important;
    }
  }
}

@mixin top-left($is-sticky) {
  @if $is-sticky {
    left: $notification-position-left !important;
    top: $notification-position-top !important;
  }

  @else {
    left: 0;
    top: 0;
  }

  @include mobile {
    left: 50% !important;
    bottom: auto !important;

    @include transform(translateX(-50%) !important);

    @if $is-sticky {
      top: $notification-position-top !important;
    }

    @else {
      top: 0 !important;
    }
  }
}

@mixin bottom-right($is-sticky) {
  top: auto !important;

  @if $is-sticky {
    right: $notification-position-right !important;
    bottom: $notification-position-bottom !important;
  }

  @else {
    right: 0;
    bottom: 0;
  }

  @include mobile {
    right: 50% !important;
    top: auto !important;

    @if $is-sticky {
      bottom: $notification-position-bottom !important;
    }

    @else {
      bottom: 0 !important;
    }
  }
}

@mixin bottom-left($is-sticky) {
  top: auto !important;

  @if $is-sticky {
    left: $notification-position-left !important;
    bottom: $notification-position-bottom !important;
  }

  @else {
    left: 0;
    bottom: 0;
  }

  @include mobile {
    left: 50% !important;
    top: auto !important;

    @include transform(translateX(-50%) !important);

    @if $is-sticky {
      bottom: $notification-position-bottom !important;
    }

    @else {
      bottom: 0 !important;
    }
  }
}
