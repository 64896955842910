.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &box {
      background-color: white;
      padding: $primary-box-padding;
      margin-bottom: 16px;
      border-radius: $radius;
      position: relative;

      &.disabled {
        opacity: 0.5;
      }

      &-disable {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-color: white;
        opacity: 0.5;
      }

      // Background
      &-mobile,
      &-transparent {
        background-color: transparent;
      }

      &-primary {
        background-color: $color-primary;
      }

      &-secondary {
        background-color: $color-secondary;
      }

      &-tertiary {
        background-color: $color-tertiary;
      }

      @include mobile {
        padding: $primary-box-padding 16px;

        &-mobile {
          background-color: white;
          padding: $primary-box-padding 16px;
        }

        &-mobile-transparent {
          background-color: transparent;
        }
      }

      &-small {
        padding: $primary-box-padding-small;
      }

      &-with-shadow {
        @include box-shadow($primary-box-shadow);
      }

      &-no-margin {
        margin-bottom: 0 !important;
      }

      &-margin-small {
        margin-bottom: 8px !important;
      }

      &-margin-large {
        margin-bottom: 40px !important;
      }
    }
  }
}
