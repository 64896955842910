@charset "utf-8";

.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &card {
      &-background {
        @include has-background-image;
        @include transition($primary-transition);
        position: absolute;
        z-index: 1;

        @include all-zero;
      }

      &-overlay {
        position: absolute;
        z-index: 1;

        @include all-zero;
        background-color: rgba($color-grey-darkest, 0.4);
      }

      &-content,
      &-title {
        overflow: hidden;
        border-radius: $radius;
        width: 100%;
        z-index: 2;
        margin-bottom: $container-gap * 2;

        @include mobile {
          width: 100%;
        }
      }

      &-content {
        border-radius: $radius;
        background-color: white;

        @include box-shadow(0 1px 2px 0 rgba(0, 0, 0, 0.1));

        &:hover {
          & .#{$travlr-prefix}card-content {
            &-header {
              &-background {
                @include transform(translate3d(-50%, -50%, 0) scale(1.1));
              }
            }
          }
        }

        &-header {
          min-height: 170px;
          max-height: 170px;
          background-color: #DDD;
          text-align: center;

          &::before {
            content: "\EA99";
            color: #CCC;

            @include icon-default;
            font-size: 72px !important;
            position: absolute;
            top: 50%;
            left: 50%;

            @include transform(translate3d(-50%, -50%, 0));
          }

          &-background {
            height: 100%;
            width: 100%;
            object-fit: cover;
            position: absolute;
            left: 50%;
            top: 50%;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;

            @include transform(translate3d(-50%, -50%, 0));
            @include transition($primary-transition);
            -webkit-appearance: none !important;
          }

          &-separator {
            border-bottom: 1px solid #EEE;
          }
        }

        &-body {
          padding: 12px 16px 0;

          &-title {
            min-height: 40px;
            max-height: 40px;
          }
        }

        &-footer {
          padding: 0 16px 12px;
          min-height: 120px;

          &-left {
            width: 40%;

            &-location {
              min-height: 40px;
              vertical-align: bottom;
              display: table-cell;
            }

            &-rating-days {
              &-value {
                //
              }

              &-rating {
                //
              }
            }
          }

          &-right {
            &-price {
              &-value {
                font-size: 15px !important;
              }
            }
          }
        }
      }

      &-title {
        padding: 24px;
        position: relative !important;
        overflow: hidden !important;
        display: block !important;

        &:hover {
          & .#{$travlr-prefix}card-background {
            @include transform(scale(1.1));
          }
        }

        &-content {
          z-index: 2;
          position: absolute !important;
          top: 50%;
          left: 50%;

          @include transform(translate3d(-50%, -50%, 0) !important);
          width: 100%;
          padding: 0 16px;

          &.top {
            top: 25%;
            padding-bottom: 24px;
          }

          &.bottom {
            top: 80%;
            padding-bottom: 24px;
          }

          &-description {
            padding: 7px 14px;
            border-radius: 16px;
            background-color: rgba($color-grey-darkest, 0.4);
          }
        }

        // Type
        &-small {
          min-height: 170px;
          max-height: 170px;

          @include mobile {
            min-height: 230px !important;
            max-height: 230px !important;
          }

          @include tablet {
            min-height: 230px !important;
            max-height: 230px !important;
          }
        }

        &-large {
          min-height: 230px;
          max-height: 230px;

          &::before {
            content: "\EA99";
            color: #CCC;

            @include icon-default;
            font-size: 72px !important;
            position: absolute;
            top: 50%;
            left: 50%;

            @include transform(translate3d(-50%, -50%, 0));
          }
        }
      }

      &-wishlist {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 2;
        color: white;
        padding: 0;
        border: 0;

        &.active,
        &:hover {
          & .icon-heart-o {
            &::before {
              font-family: $typeface-travlr-icon;
              content: "\EA90";
              color: $color-danger;
            }
          }
        }
      }

      &-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 10;
      }

      /*
       * Card Skeleton for Loading
       */

      @keyframes placeholder {
        0% {
          background-position: -468px 0;
        }

        100% {
          background-position: 468px 0;
        }
      }

      @mixin animated-background {
        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeholder;
        animation-timing-function: linear;
        background: #DDD;
        background: linear-gradient(to right, #DDD 8%, #F0F0F0 18%, #DDD 33%);
        background-size: 800px 104px;
        position: relative;
      }

      &-skeleton {
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        @include transition($primary-transition);

        &.active {
          opacity: 1;
          visibility: visible;
        }

        &-image {
          height: 170px;
          width: 100%;

          @include animated-background;
          border-top-left-radius: $radius;
          border-top-right-radius: $radius;
        }

        &-text {
          padding: 16px;
          background-color: white;
        }

        &-line {
          height: 16px;
          margin-bottom: 6px;

          @include animated-background;

          &.small {
            height: 12px;
          }

          &.line {
            &-1 {
              width: 100%;
            }

            &-2 {
              width: 50%;
              margin-bottom: 16px;
            }

            &-3 {
              width: 20%;
            }

            &-4 {
              width: 40%;
            }
          }
        }
      }
    }
  }
}
