@charset "utf-8";

.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &tabs {
      padding: 0 24px;
      background-color: white;
      border-radius: $radius;
      max-height: 48px;

      &-title {
        @extend .t-label-text-small;
        color: $color-grey-med;
        display: inline-block;
        margin-right: 56px;
      }

      &-inner {
        display: inline-block;
        padding: 0;
        margin: 0;
      }

      &-item {
        display: inline-block;

        @include transition($primary-transition);

        &:hover,
        &:visited,
        &:focus,
        &.active {
          & .t-tabs-link {
            border-bottom: 4px solid $color-primary;
            color: $color-primary;
          }
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      &-link {
        @extend .t-label-text-small;
        color: $color-grey-darkest;
        max-height: 48px;
        min-height: 48px;
        line-height: 48px;
        display: block;
        padding: 0 24px;
        border-bottom: 4px solid transparent;
      }

      // Background
      &-primary {
        background-color: $color-tertiary !important;
      }

      // Position
      &-center {
        //
      }
    }
  }
}

// Responsive
@import "tabs-responsive";
