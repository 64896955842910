@charset "utf-8";

.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &accordion {
      padding: 16px 0;
      margin: 0;

      &-icon {
        display: table-cell !important;
        vertical-align: middle;
        text-align: right;
      }

      &-title {
        font-size: $size-text-5;
        line-height: $line-height-text-5;
        font-weight: $weight-bold;

        @include typography-text-normal;
        color: $color-grey-darkest;
        display: table-cell;
        vertical-align: middle;
      }

      &-item {
        border-bottom: 1px solid $primary-border-color;

        @include transition(max-height 0.15s ease-out);
        height: auto;
        overflow: hidden;

        &:last-child {
          border-bottom: 0;
        }

        &.active,
        &-list.active,
        > li.active {
          max-height: none;
          @include transition(max-height 0.25s ease-in);
          
          > .t-accordion-item-header {
            > .t-accordion-icon {
              @include transform(rotate(180deg));
              text-align: left;
            }
          }

          > .t-accordion-item-body {
            max-height: unset;
            padding: 12px 0;
          }
        }

        &-header {
          display: table;
          width: 100%;
          height: 45px;
          min-height: 45px;
          cursor: pointer;
        }

        &-body {
          display: block;
          font-size: $size-text-body;
          line-height: $line-height-text-body;
          font-weight: normal;

          @include typography-text-normal;
          color: $color-grey-darkest;
          max-height: 0;
          overflow: hidden;
        }
      }
    }
  }
}
