@charset "utf-8";

.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &pagination {
      float: right;
      padding: 0;
      margin: 0;
      min-height: 40px;
      max-height: 40px;

      @include mobile {
        float: none;
        width: 100%;
        text-align: center;
        margin-top: 16px;
      }

      &-wrapper {
        @include clearfix;
      }

      &-title {
        float: left;
        line-height: 40px;

        @extend .t-body-text;
        color: $color-grey-darkest;

        @include mobile {
          float: none;
          width: 100%;
          text-align: center;
        }
      }

      li {
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        text-align: center;
        background-color: white;
        border-radius: 100%;
        cursor: pointer;
        margin-right: 8px;

        @include transition($primary-transition);
        border: 1px solid transparent;
        color: $color-grey-darker;

        @extend .t-heading-6;

        a {
          width: 40px;
          height: 40px;
          line-height: 40px;
          display: table-cell;
        }

        span,
        a,
        div {
          color: $color-grey-darker;

          @extend .t-heading-6;
        }

        &:first-child {
          position: relative;

          &::after {
            font-family: $typeface-travlr-icon;
            top: 50%;
            left: 50%;
            background: white;
            border-radius: 100%;
            width: 38px;
            height: 38px;
            font-size: 24px;

            @include transform(translate3d(-50%, -50%, 0));
            position: absolute;
            content: "\EA0A";
            pointer-events: none;
          }
        }

        &:last-child {
          position: relative;
          margin-right: 0;

          &::after {
            font-family: $typeface-travlr-icon;
            top: 50%;
            left: 50%;
            background: white;
            border-radius: 100%;
            width: 38px;
            height: 38px;
            font-size: 24px;

            @include transform(translate3d(-50%, -50%, 0));
            position: absolute;
            content: "\EA09";
            pointer-events: none;
          }
        }

        &:hover {
          border: 1px solid $color-secondary;
        }

        &.active {
          color: white;
          background-color: $color-secondary;

          a,
          span,
          p {
            color: white;
          }
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;

          &:hover {
            border: 1px solid transparent !important;
            pointer-events: unset;
          }
        }
      }

      /deep/ {
        .paginate-links {
          @include mobile {
            display: inline-block;
          }
        }
      }
    }
  }
}

// Responsive
@import "pagination-responsive";
