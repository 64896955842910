@charset "utf-8";

.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &hero {
      &-main {
        position: relative;
        min-height: $hero-main-height;
        max-height: $hero-main-height;

        @include mobile {
          position: inherit;
          min-height: unset !important;
          max-height: unset !important;
        }

        &-background {
          @include has-background-image;
          width: 100%;
          min-height: $hero-main-height;
          max-height: $hero-main-height;
          z-index: 1;
          position: absolute;
          top: 0;
          left: 0;

          @include mobile {
            min-height: 312px;
            max-height: 312px;
          }
        }

        &-container {
          position: relative;
          z-index: 3;

          @include mobile {
            padding: 0 !important;
            position: inherit;
          }
        }

        &-content {
          z-index: 2;
          position: relative;
          height: $hero-main-height;
          max-height: $hero-main-height;
          min-height: $hero-main-height;

          @include mobile {
            position: inherit;
            min-height: unset !important;
            max-height: unset !important;
          }

          &-list {
            @include mobile {
              display: block !important;
            }

            &-title {
              @include mobile {
                height: 312px;
                min-height: 312px;
                max-height: 312px;
                padding: 0 16px;
                display: table;
                width: 100%;
                position: relative;
                z-index: 1;

                &-wrapper {
                  @include mobile {
                    display: table-cell;
                    vertical-align: bottom;
                    padding-bottom: 24px;
                    z-index: 9;
                  }
                }
              }
            }

            &-booking {
              @include mobile {
                margin-top: 0;
                padding: 24px 16px;
                background-color: white;

                & .t-input-label {
                  color: $color-grey-darker;
                }
              }
            }
          }
        }
      }

      &-small {
        //
      }

      &-overlay {
        position: absolute;

        @include all-zero;
        background-color: rgba($color-grey-darker, 0.4);
        z-index: 1;
      }
    }
  }
}

@import "hero-responsive";
