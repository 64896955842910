@include mobile {

  .#{$travlr-ui} {

    .#{$travlr-prefix} {
      &section- {
        &small {
          padding: calc(50px / 2) 0;
        }

        &medium {
          padding: calc(80px / 2) 0;
        }

        &large {
          padding: calc(100px / 2) 0;
        }
      }
    }
  }
}
