@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-montserrat-path}Montserrat-SemiBold.eot');
  src:
    url('#{$font-montserrat-path}Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('#{$font-montserrat-path}Montserrat-SemiBold.woff2') format('woff2'),
    url('#{$font-montserrat-path}Montserrat-SemiBold.woff') format('woff'),
    url('#{$font-montserrat-path}Montserrat-SemiBold.ttf') format('truetype'),
    url('#{$font-montserrat-path}Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-montserrat-path}Montserrat-Bold.eot');
  src:
    url('#{$font-montserrat-path}Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$font-montserrat-path}Montserrat-Bold.woff2') format('woff2'),
    url('#{$font-montserrat-path}Montserrat-Bold.woff') format('woff'),
    url('#{$font-montserrat-path}Montserrat-Bold.ttf') format('truetype'),
    url('#{$font-montserrat-path}Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-montserrat-path}Montserrat-Regular.eot');
  src:
    url('#{$font-montserrat-path}Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$font-montserrat-path}Montserrat-Regular.woff2') format('woff2'),
    url('#{$font-montserrat-path}Montserrat-Regular.woff') format('woff'),
    url('#{$font-montserrat-path}Montserrat-Regular.ttf') format('truetype'),
    url('#{$font-montserrat-path}Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
