.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &heading {
      &-1,
      &-2,
      &-3,
      &-4,
      &-5,
      &-6 {
        font-weight: $weight-bold;

        @include typography-text-normal;
      }

      &-1 {
        font-size: $size-text-1;
        line-height: $line-height-text-1;
      }

      &-2 {
        font-size: $size-text-2;
        line-height: $line-height-text-2;
      }

      &-3 {
        font-size: $size-text-3;
        line-height: $line-height-text-3;
      }

      &-4 {
        font-size: $size-text-4;
        line-height: $line-height-text-4;
      }

      &-5 {
        font-size: $size-text-5;
        line-height: $line-height-text-5;
      }

      &-6 {
        font-size: $size-text-6;
        line-height: $line-height-text-6;
      }
    }

    &body-text {
      font-size: $size-text-body;
      line-height: $line-height-text-body;
      font-weight: normal;

      @include typography-text-normal;
    }

    &supplementary-text {
      font-size: $size-text-body;
      line-height: $line-height-text-supplementary;
      font-weight: normal;

      @include typography-text-normal;
    }

    &label-text {
      font-size: $size-text-label-text;
      line-height: $line-height-text-label-text;
      font-weight: normal;

      @include typography-text-normal;
    }

    &label-text-small {
      font-size: $size-text-label-text-small;
      line-height: $line-height-text-label-text-small;
      font-weight: normal;

      @include typography-text-normal;
    }

    &text-callout {
      font-weight: $weight-semibold;
      font-size: $size-text-body;
      line-height: $line-height-text-callout;

      @include typography-text-normal;
    }

    &text-callout-small {
      font-weight: $weight-semibold;
      font-size: $size-text-small;
      line-height: $line-height-text-callout-small;

      @include typography-text-normal;
    }

    &caption {
      font-weight: $weight-regular;
      font-size: $size-text-caption;
      line-height: $line-height-text-caption;

      @include typography-text-normal;
    }

    &caption-small {
      font-weight: $weight-regular;
      font-size: $size-text-small;
      line-height: $line-height-text-caption;

      @include typography-text-normal;
    }

    &heading-mobile {
      @include mobile {
        font-size: $size-text-3;
      }
    }
  }
}
