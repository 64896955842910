.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &image {

      // Rounded
      &-circle {
        border-radius: 100%;
      }

      &-rounded {
        border-radius: $radius;
      }

      // Avatar
      &-avatar,
      &-avatar-small,
      &-avatar-large {
        border-radius: 100%;

        @include has-background-image;
        background-color: $color-grey-lighter;
      }

      &-avatar {
        width: $avatar-width;
        height: $avatar-height;
        background-color: $color-grey-lighter;

        // Type
        &-border {
          border: 2px solid $color-grey-lighter;
          background-color: $color-grey-lighter;
        }

        // Size
        &-extra-small {
          width: $avatar-width-extra-small;
          height: $avatar-height-extra-small;
        }

        &-small {
          width: $avatar-width-small;
          height: $avatar-height-small;
        }

        &-large {
          width: $avatar-width-large;
          height: $avatar-height-large;
        }
      }

      // Thumb
      &-thumb {
        @include has-background-image;
        overflow: hidden;
        border-radius: $radius;
        background-color: $color-grey-lighter;

        &-small {
          width: $thumb-width-small;
          height: $thumb-height-small;
        }

        &-medium {
          width: $thumb-width-medium;
          height: $thumb-height-medium;
        }

        &-large {
          width: $thumb-width-large;
          height: $thumb-height-large;
        }
      }
    }
  }
}
