@charset "utf-8";

.#{$travlr-ui} {
  .#{$travlr-prefix} {
    &notification {
      max-width: 368px;
      min-width: 368px;
      color: $color-grey-darkest;
      position: relative;
      right: 0;
      top: 0;
      z-index: 1001;
      opacity: 0;
      visibility: hidden;

      @include transition($primary-transition);
      @include transform(translate3d(0,-100%,0));

      &.active {
        opacity: 1;
        visibility: visible;

        @include transform(translate3d(0, 0, 0));
      }

      @include mobile {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        // right: 50% !important;
        // bottom: 10px !important;
        transform: translateX(50%) !important;
        // top: auto !important;
      }

      @include top-right(false);

      &-content {
        background-color: white;
        padding: 20px 24px;
        border-radius: $radius;

        @include box-shadow($secondary-box-shadow);
        display: table;
        width: 100%;
        border: 1px solid $color-grey-lighter;

        @include mobile {
          border-radius: 0 !important;
        }
      }

      &-body {
        display: table-cell;
        vertical-align: top;

        @include mobile {
          max-width: 100%;
          min-width: 100%;
          border-radius: 0 !important;
          height: 48px !important;
          line-height: 48px !important;
        }
      }

      &-close {
        display: table-cell;
        vertical-align: top;
      }

      // Background
      @mixin default-notification {
        font-family: $typeface-travlr-icon;
        border-radius: 100%;
        text-align: center;
        color: white;
        width: 26px;
        height: 26px;
      }

      @mixin default-notification-icon {
        display: table-cell;
        vertical-align: top;
        padding-right: 16px;
        width: 26px;
        height: 26px;
      }

      &.default {

        & .#{$travlr-prefix}notification {
          &-icon {
            @include default-notification-icon;

            & .t-icon {
              &::after {
                @include default-notification;
                color: $color-secondary !important;
                content: "\EA9C";
                font-size: 32px;
              }
            }
          }
        }
      }

      &.fluid {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
      }

      &.sticky {
        position: fixed !important;
        right: $notification-position-right;
        top: $notification-position-top;

        &.fluid {
          min-width: 95%;
          max-width: 95%;
          width: 95%;
        }

        @include mobile {
          min-width: 95%;
          max-width: 95%;
          width: 95%;
          top: $notification-position-top !important;
        }
      }

      &.danger {

        & .#{$travlr-prefix}notification {
          &-icon {
            @include default-notification-icon;

            & .t-icon {
              &::after {
                @include default-notification;
                color: $color-danger !important;
                content: '\ea53';
                font-size: 32px;
              }
            }
          }
        }
      }

      &.warning {

        & .#{$travlr-prefix}notification {
          &-icon {
            @include default-notification-icon;

            & .t-icon {
              &::after {
                @include default-notification;
                color: $color-warning !important;
                content: "\EA6F";
                font-size: 32px;
              }
            }
          }
        }
      }

      &.success {

        & .#{$travlr-prefix}notification {
          &-icon {
            @include default-notification-icon;

            & .t-icon {
              &::after {
                @include default-notification;
                color: $color-success !important;
                content: "\EA4C";
                font-size: 32px;
              }
            }
          }
        }
      }

      &.top-right {
        @include top-right(true);
      }

      &.top-left {
        @include top-left(true);
      }

      &.bottom-right {
        @include bottom-right(true);
      }

      &.bottom-left {
        @include bottom-left(true);
      }
    }
  }
}
