@charset "utf-8";

.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &navbar {
      &-separator {
        height: 1px;
      }

      &-search-dropdown {
        position: absolute;
        top: 100%;
        width: 100%;
        min-width: 400px;
        background-color: white;
        padding: 8px 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        @include transition($primary-transition);

        @include mobile {
          min-width: 100% !important;
          box-shadow: none !important;
        }

        &.active {
          pointer-events: fill;
          opacity: 1;
          visibility: visible;
        }

        &-separator {
          padding: 10px 14px 10px 18px;

          &-title {
            position: relative;
            width: 100%;
            display: block;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: 0.2px;
            color: $color-grey-dark;
            z-index: 2;

            &-text {
              position: relative;
              z-index: 2;
              padding-right: 16px;
              background-color: white;
            }
          }

          &-line {
            width: 100%;
            display: block;
            z-index: 0;

            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              right: 0;
              top: 50%;

              @include transform(translateY(-50%));
              background-color: $color-grey_lighter;
            }
          }
        }

        &-list {
          &-item {
            &-link {
              padding: 10px 14px 10px 18px;

              @include transition($primary-transition);

              @include mobile {
                padding: 10px 0 !important;

                &:hover {
                  background-color: transparent !important;
                }
              }

              &:hover {
                background-color: $color-grey-lightest;
              }
            }
          }
        }
      }

      // navbar-top
      &-top {
        top: 0;
        left: 0;
        width: 100%;

        @include box-shadow($primary-box-shadow);
        z-index: 1005;

        &-main {
          @include mobile {
            padding: 0 16px !important;
          }
        }

        &-left {
          height: 64px;

          &-burger {
            height: 64px;
          }

          &-logo {
            &-image {
              width: 150px;
              height: 48px;

              @include mobile {
                max-width: 100px;
              }
            }
          }

          &-menus {
            &-list {
              line-height: 64px;
              min-height: 64px;

              &-item {
                position: relative;

                &-link {
                  padding: 24px 20px;

                  @include transition($primary-transition);
                }

                %child {
                  padding: 8px 0 !important;
                  border: 1px solid $color-grey-light;
                  opacity: 0;
                  visibility: hidden;
                  min-width: 230px;

                  @include transition(all 0.2s ease);
                }
                %child-item {
                  padding: 10px 14px 10px 18px;

                  @include transition($primary-transition);

                  &:hover {
                    background-color: $color-grey-lightest;
                  }
                }

                &-child {
                  border-top: 4px solid $color-primary !important;
                  top: 100%;
                  left: 0;
                  pointer-events: none;

                  @extend %child;

                  &.with-limit {
                    max-height: 435px;
                    overflow: auto;
                  }

                  &-item {
                    position: relative;

                    &-link {
                      @extend %child-item;
                      display: table;
                      width: 100%;
                      height: 40px;

                      & .t-icon {
                        display: table-cell;
                        vertical-align: middle;
                        text-align: right;
                        font-size: 20px;
                        color: $color-grey-dark;
                      }
                    }

                    &-grandchild {
                      top: 0;
                      left: 100%;

                      @extend %child;

                      &-item {
                        position: relative;

                        &-link {
                          @extend %child-item;
                        }
                      }
                    }

                    &:focus,
                    &:hover {
                      .#{$travlr-prefix} {
                        &navbar {
                          &-top {
                            &-left {
                              &-menus {
                                &-list {
                                  &-item {
                                    &-child {
                                      &-item {
                                        &-link {
                                          background-color: $color-grey-lightest;
                                        }

                                        &-grandchild {
                                          opacity: 1 !important;
                                          visibility: visible;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                &:focus,
                &:hover {
                  .#{$travlr-prefix} {
                    &navbar {
                      &-top {
                        &-left {
                          &-menus {
                            &-list {
                              &-item {
                                &-link {
                                  color: $color-primary;
                                }

                                &-child {
                                  opacity: 1 !important;
                                  visibility: visible;
                                  pointer-events: fill;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &-right {
          height: 64px;

          &-search-mobile {
            height: 64px;
          }

          &-cart {
            height: 64px;
          }

          &-search-container {
            //
          }

          &-search-suggestion-form {
            max-width: 200px;
          }

          &-auth-container {
            //
          }

          &-menus-list {
            &-item {
              position: relative;

              &-link {
                padding: 20px;
                cursor: pointer;

                &-myaccount,
                & .icon-cadet-down-small {
                  font-size: 16px;
                }
              }

              &-child {
                padding: 8px 0 !important;
                border: 1px solid $color-grey-light;
                opacity: 0;
                visibility: hidden;
                min-width: 260px;

                @include transition(all 0.2s ease);
                right: 0;
                pointer-events: none;

                &-item {
                  position: relative;

                  &:hover {
                    background-color: $color-grey-lightest;
                  }

                  &-link {
                    padding: 10px 14px 10px 18px;

                    @include transition($primary-transition);
                    display: table;
                    width: 100%;
                    height: 40px;

                    & .t-icon {
                      display: table-cell;
                      vertical-align: middle;
                      text-align: right;
                      font-size: 20px;
                      color: $color-grey-dark;
                    }
                  }
                }
              }

              &.active,
              &:hover {
                .#{$travlr-prefix} {
                  &navbar {
                    &-top {
                      &-right {
                        &-menus {
                          &-list {
                            &-item {
                              &-link {
                                color: $color-primary;
                              }

                              &-child {
                                opacity: 1 !important;
                                visibility: visible;
                                pointer-events: fill;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              @include hover-supported() {
                .#{$travlr-prefix} {
                  &navbar {
                    &-top {
                      &-right {
                        &-menus {
                          &-list {
                            &-item {
                              &-link {
                                color: $color-primary;
                              }

                              &-child {
                                opacity: 1 !important;
                                visibility: visible;
                                pointer-events: fill;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // navbar-top end

      // navbar-sidebar
      &-sidebar {
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: 100%;
        z-index: 1010;

        @include transition($primary-transition);

        &.active {
          opacity: 1 !important;
          visibility: visible !important;

          @include transition($primary-transition);

          & .t-navbar-sidebar-inner {
            @include transform(translate3d(0, 0, 0));
          }

          & .t-navbar-sidebar-overlay {
            opacity: 1 !important;
            visibility: visible !important;
          }
        }

        &-inner {
          width: 85%;
          max-width: 320px;
          background-color: white;
          height: 100%;
          padding: 16px 0;
          z-index: 999;

          @include transform(translate3d(-100%, 0, 0));
          @include transition($primary-transition);
          overflow: auto;
        }

        &-overlay {
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
          width: 100%;
          height: 100%;
          z-index: 998;
          background-color: rgba(black, 0.5);

          @include transition($primary-transition);
        }

        &-header {
          &-icon {
            width: 24px;
          }

          &-logo {
            max-width: 150px;
          }
        }

        &-body {
          padding: 0 16px;

          &-child {
            padding: 0;

            &-item {
              display: block;

              &.has-child {
                display: table;
                width: 100%;

                & .t-navbar-sidebar-body-child-item-link {
                  display: table-cell;
                  vertical-align: middle;
                }

                & button {
                  &.btn-transparent {
                    padding: 16px 0;
                  }
                }
              }

              &-link {
                padding: 16px;
                display: block;
                font-size: $size-text-body;
                line-height: $line-height-text-body;
                font-weight: normal;

                @include typography-text-normal;
                color: $color-grey-darkest;
              }
            }
          }
        }

        &-submenu {
          position: absolute;
          top: 0;
          left: 0;
          background-color: white;
          width: 100%;
          height: 100%;
          overflow: auto;
          opacity: 0;
          visibility: hidden;

          @include transform(translate3d(-100%, 0, 0));
          @include transition($primary-transition);

          &.active {
            opacity: 1;
            visibility: visible;

            @include transform(translate3d(0,0,0));
          }
        }
      }
      // navbar-sidebar end

      // navbar-bottom
      &-bottom {
        padding-top: 50px;
        color: white;

        &-widget {
          &-item {
            @include mobile {
              padding: 25px 0;
              border-bottom: 1px solid $color-secondary;

              &:last-child {
                padding-bottom: 0;
                border-bottom: 0 !important;
              }
            }
          }
        }

        &-main {
          padding: 20px 0 30px;

          &-left {
            min-height: 50px;

            &-logo {
              &-bb {
                height: 48px;
              }

              &-afta {
                width: auto;
                height: 44px;
              }
            }

            &-copyright {
              max-width: 242px;

              @include mobile {
                max-width: unset;
              }
            }
          }

          &-right {
            min-height: 50px;
          }
        }
      }

      &-footer {
        &-main {
          background-color: $color-tertiary;
          min-height: 96px;

          & .#{$travlr-prefix} {
            &navbar {
              &-main {
                display: block;
              }

              &-minimal {
                display: none;
              }
            }
          }
        }

        &-minimal {
          background-color: transparent !important;

          & .#{$travlr-prefix} {
            &navbar {
              &-minimal {
                display: block;
              }
            }
          }
        }
      }
      // navbar-bottom end

      /* Main Menu with Scroll */
      &-with-scroll {
        & .list-item {
          &-parent {
            &:hover {
              & .list-item-parent-a {
                color: $color-primary;
              }

              & .first-level {
                visibility: visible;

                @include opacity(1);
              }
            }

            &-a {
              padding: 24px 20px;
              display: block;
              cursor: pointer;

              @include transition(all 0.5s ease);

              &:hover {
                color: $color-primary;
              }
            }
          }

          &-child {
            width: 100%;
            display: table;
            table-layout: fixed;
            vertical-align: middle;

            &:hover {
              background-color: $color-grey-lightest;

              & .second-level {
                visibility: visible;

                @include opacity(1);
              }
            }

            &-a {
              width: 100%;
              padding: 10px 10px 10px 18px;
              display: table;
              cursor: pointer;
            }

            & .t-icon {
              display: table-cell;
              vertical-align: middle;
              text-align: right;
              font-size: 20px;
              color: #8E9497;
            }
          }
        }

        & .first-level {
          top: 100%;
          left: 0;

          &-menu {
            height: 500px;
            overflow-y: auto;
            overflow-x: hidden;
          }
        }

        & .second-level {
          top: 100%;
          left: 0;

          &-menu {
            height: 503px;
            overflow-y: auto;
            overflow-x: hidden;
          }
        }

        & .dropdown {
          &-menu {
            width: 230px;
            padding: 8px 0;
            visibility: hidden;
            position: absolute;
            border-left: 1px solid $color-grey-light;
            border-right: 1px solid $color-grey-light;
            border-bottom: 1px solid $color-grey-light;

            @include transition(all 0.3s ease);
            @include opacity(0);

            &.with-blue-border {
              border-top: 4px solid $color-primary;
            }

            &.with-border {
              border-top: 1px solid $color-grey-light;
            }

            &.first-level {
              top: 100%;
              left: 0;
            }

            &.second-level {
              top: -4px;
              left: 228px;
            }
          }
        }
      }

      /* End Main Menu with Scroll */
    }
  }
}
