@charset "utf-8";

.#{$travlr-ui} {
  .#{$travlr-prefix} {
    &alert-alt {
      width: 100%;
      border-radius: 8px;
      opacity: 0;
      visibility: hidden;
      display: none;

      @include transition($primary-transition);

      &-body {
        padding: 12px 16px;
        display: table;
        width: 100%;
      }

      // Trigger Show
      &.active {
        display: block;
        opacity: 1 !important;
        visibility: visible !important;
      }

      // Background
      &.default {
        color: $color-grey-darkest;
        background-color: rgba($color-grey-med, 0.1) !important;
      }

      &.danger {
        color: $color-danger;
        background-color: rgba($color-danger, 0.1) !important;
      }

      &.warning {
        color: $color-warning;
        background-color: rgba($color-warning, 0.1) !important;
      }

      &.success {
        color: $color-success;
        background-color: rgba($color-success, 0.1) !important;
      }

      &-icon {
        display: table-cell !important;
        vertical-align: middle;
        width: 24px;
        padding-right: 12px;
      }

      &-text {
        display: table-cell;
        vertical-align: middle;
        font-size: $size-text-body;
        line-height: $line-height-text-body;
        font-weight: normal;

        @include typography-text-normal;
      }
    }
  }
}
