@charset "utf-8";

.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &panel {
      background-color: white;
      margin-bottom: 8px;

      &-header {
        padding: 0 $primary-panel-padding;
        min-height: 48px;
        max-height: 48px;
        border-bottom: 1px solid $color-grey-lighter;
        display: table;
        width: 100%;
        cursor: pointer;

        %heading {
          font-size: $size-text-6;
          line-height: $line-height-text-6;
          font-weight: $weight-bold;

          @include typography-text-normal;
        }

        %middle {
          display: table-cell;
          vertical-align: middle;
        }

        &-title {
          color: $color-grey-darkest;

          @extend %heading;
          @extend %middle;
        }

        &-icon {
          text-align: right;

          @extend %middle;
          font-size: $icon-medium;
          color: $color-grey-dark;
          cursor: pointer;
        }

        &.active {
          & .t-panel-header-icon {
            @include transform(rotate(180deg));
            text-align: left;
          }
        }
      }

      &-body {
        padding: 0;

        @include transition(max-height 0.15s ease-out);
        height: auto;
        max-height: 0;
        overflow: hidden;

        @include transition(max-height 0.25s ease-in);
        position: relative;

        &.active {
          padding: $primary-panel-padding $primary-panel-padding;
          max-height: none;

          @include transition(max-height 0.25s ease-in);

          & .t-panel-header-icon {
            @include transform(rotate(180deg));
            text-align: left;
          }
        }

        &.skeleton-active {
          min-height: 150px;
          max-height: 150px;
        }

        &-small {
          padding: ($primary-panel-padding / 2) $primary-panel-padding;
        }
      }

      &-footer {
        padding: 0 $primary-panel-padding $primary-panel-padding;

        &-view-all {
          color: $color-primary;
          font-size: $size-text-6;
          line-height: $line-height-text-6;
          font-weight: $weight-bold;

          @include typography-text-normal;
        }
      }

      /*
       * Skeleton for Loading
       */

      @keyframes placeholder {
        0% {
          background-position: -468px 0;
        }

        100% {
          background-position: 468px 0;
        }
      }

      @mixin animated-background {
        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeholder;
        animation-timing-function: linear;
        background: #DDD;
        background: linear-gradient(to right, #DDD 8%, #F0F0F0 18%, #DDD 33%);
        background-size: 800px 104px;
        position: relative;
      }

      &-skeleton {
        position: absolute;
        z-index: 1001;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        @include transition($primary-transition);

        &.active {
          opacity: 1;
          visibility: visible;
        }

        &-text {
          padding: 16px;
          background-color: white;
        }

        &-line {
          height: 16px;
          margin-bottom: 16px;

          @include animated-background;

          &.small {
            height: 12px;
          }

          &.line {
            &-1 {
              width: 100%;
            }

            &-2 {
              width: 80%;
            }

            &-3 {
              width: 50%;
            }

            &-4 {
              width: 70%;
            }
          }
        }
      }
    }
  }
}

// Responsive
@import "panel-responsive";
