.#{$travlr-ui} {
  & .el-date-picker {
    @include mobile {
      z-index: 9999 !important;
    }
  }

  & .el-input::-webkit-scrollbar {
    z-index: 0 !important;
  }

  .#{$travlr-prefix} {
    &date-picker {
      &-large {
        /deep/ {
          & .#{$element-prefix} {
            &input {
              &__inner {
                line-height: 48px !important;
                height: 48px !important;
              }
            }
          }
        }
      }

      &-transparent {
        /deep/ {
          & .#{$element-prefix} {
            &input {
              &__inner {
                background-color: transparent !important;
              }
            }
          }
        }
      }
    }
  }
}
