.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &container {
      max-width: $container-width;
      margin: 0 auto;
      padding: 0 $container-gap;

      &-small {
        @include mobile {
          &-mobile {
            padding: 0 ($container-gap-mobile * 2) !important;
          }
        }
      }

      // Mobile
      @include mobile {
        padding: 0 ($container-gap-mobile * 2);
      }

      // Tablet Portrait
      @include tablet {
        padding: 0 ($container-gap-mobile * 3);
      }

      // Tablet Landscape
      @include until(1025px) {
        padding: 0 ($container-gap-mobile * 3);
      }
    }

    // new container
    &#{$travlr-class-migration} {
      & .#{$travlr-prefix} {
        &container {
          margin-left: auto;
          margin-right: auto;
          padding-left: ($grid-gutter-width * 2);
          padding-right: ($grid-gutter-width * 2);
          width: 100%;

          @include media-up(sm) {
            padding-left: ($grid-gutter-width * 3);
            padding-right: ($grid-gutter-width * 3);
          }

          @include media-up(md) {
            padding-left: ($grid-gutter-width * 4);
            padding-right: ($grid-gutter-width * 4);
          }

          @include media-up(xl) {
            padding-left: ($grid-gutter-width);
            padding-right: ($grid-gutter-width);
            max-width: $container-width;
          }
        }
      }
    }
  }
}
