@charset "utf-8";

.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &slider {
      border-radius: $radius;
      background-color: hsl(0, 0%, 0%);
      position: relative;

      &-body {
        position: relative;
        min-height: 480px;
        height: 480px;

        @include mobile {
          min-height: 250px;
          height: 250px;
        }

        &.no-data {
          min-height: 480px;
          max-height: 480px;

          @include mobile {
            min-height: 250px;
            max-height: 250px;
          }

          &::before {
            content: "\EA99";
            color: #FFF;

            @include icon-default;
            font-size: 82px !important;
            position: absolute;
            top: 50%;
            left: 50%;

            @include transform(translate3d(-50%, -50%, 0));
          }
        }
      }

      &-item {
        text-align: center;
        min-height: 480px;
        max-height: 480px;
        width: 100%;
        overflow: hidden;

        > * {
          vertical-align: middle;
        }

        @include mobile {
          min-height: 250px;
          max-height: 250px;
        }

        &-img {
          max-width: inherit;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;

          @include transform(translate3d(-50%, -50%, 0));
        }
      }

      &-header,
      &-footer {
        width: calc(100% - 48px);
        margin: 0 auto;
        left: 24px;
        z-index: 3;
        position: absolute;

        @include mobile {
          left: 16px;
          width: calc(100% - 32px);
        }
      }

      &-header {
        top: 24px;

        @include mobile {
          top: 0;
          width: 100%;
          left: 0;
          background-color: $color-semi-dark;
          padding: 6px 16px;
          position: relative;
        }
      }

      &-footer {
        bottom: 24px;

        @include mobile {
          bottom: 16px;
        }
      }

      &-overlay {
        position: absolute;

        @include all-zero;
        width: 100%;
        height: 100%;
        background-color: rgba($color-grey-darkest, 0.2);
        z-index: 1;
      }

      &-gradient {
        &-top,
        &-bottom {
          position: absolute;
          left: 0;
          width: 100%;
          height: 96px;
          z-index: 2;
        }

        &-top {
          top: 0;
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(55, 64, 68, 0.4));
        }

        &-bottom {
          bottom: 0;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(55, 64, 68, 0.4));
        }
      }
    }
  }

  // Override Slider Slidshow
  .lg-outer {
    & .lg-sub-html {
      display: none;
    }

    %icon {
      width: 64px;
      height: 64px;
      background: #374044;
      border-radius: 100%;
    }

    & .lg-actions {
      @include mobile {
        display: none;
      }
    }

    & .lg-next {
      @extend %icon;
      right: 50px;

      &::before {
        @include icon-default;
        content: "\EA0F";
      }
    }

    & .lg-prev {
      @extend %icon;
      left: 50px;

      &::after {
        @include icon-default;
        content: "\EA08";
      }
    }

    & .lg-thumb-outer {
      padding: 0 16px;
    }

    & .lg-thumb-item {
      border: 4px solid transparent;
    }

    & .lg-thumb-item.active,
    & .lg-thumb-item:hover {
      border-color: #6BA4B8;
    }
  }
}

// Responsive
@import "slider-responsive";
