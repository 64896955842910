.#{$travlr-ui} {

  .#{$travlr-prefix} {
    /* ------------------------
      base class definition
    ------------------------- */
    &icon {

      &.icon {
        /* ------------------------
          change icon size
        ------------------------- */

        /* relative units */
        &-small {
          font-size: 20px;
        }

        &-large {
          font-size: 32px;
        }

        /* absolute units */
        &-16 {
          font-size: 16px;
        }

        &-32 {
          font-size: 32px;
        }

        &-inline {
          position: relative;
          top: 6px;
        }

        /* ----------------------------------
          add a square/circle background
        ----------------------------------- */

        &-bg-square,
        &-bg-circle {
          padding: 0.35em;
          background-color: $icon-background-color;
        }

        &-bg-circle {
          border-radius: 50%;
        }
      }

      /* ------------------------
        spinning icons
      ------------------------- */

      &-is-spinning {
        -webkit-animation: icon-spin 2s infinite linear;
        -moz-animation: icon-spin 2s infinite linear;
        animation: icon-spin 2s infinite linear;
      }

      /* ------------------------------------
        use icons as list item markers
      ------------------------------------- */

      &-group {
        padding-left: 0;
        list-style-type: none;

        & > li {
          display: inline-block;
          line-height: 1.4;
        }

        & > li > .icon {
          margin-right: $icon-li-margin-right;
          line-height: inherit;
        }
      }
    }
  }
}

/* ------------------------
	Helpers
------------------------- */
@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}
