.#{$travlr-ui} {

  // Override Range Slider
  .#{$element-prefix} {
    &slider__runway {
      width: 100%;
      height: 3px;
      margin: 16px auto;
      background-color: #E4E7ED;
      border-radius: 3px;
      position: relative;
      cursor: pointer;
      vertical-align: middle;
    }

    &slider__bar {
      height: 3px;
      background-color: #006272;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      position: absolute;
      z-index: 0;
    }

    &slider__button-wrapper {
      @include flex-init();
      @include align-items(center);
      @include justify-content(center);
      height: 26px;
      width: 26px;
      position: absolute;
      z-index: 1001;
      top: -12px;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      text-align: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      line-height: normal;
      border: solid 1px #006272;
      border-radius: 100%;
      background: white;
    }

    &slider__button {
      width: 10px;
      height: 12px;
      border: solid 2px #006272;
      border-radius: 50%;
      transition: 0.2s;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 7px;
      background: #006272;
    }
  }
}
